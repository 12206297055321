import "../styles/Mention.css"

function Mention({page, choixPage})
{
    return (
        <div className="mention">
            <h1>Mentions Légales</h1>
            <h2>Éditeur :</h2>
            <p>
                SOS Diogene, Société par actions simplifiée à associé unique au capital de 1000 € dont le siège social est situé au 
                4 rue Arthur Honegger - 78100 SAINT-GERMAIN-EN-LAYE, immatriculée au Registre du Commerce et des Sociétés de Versailles 
                sous le numéro 903 557 437. N° de TVA Intracommunautaire : FR48903557437
                Téléphone : 09 73 88 59 68. Courrier électronique : sosdiogene@yahoo.com
            </p>
            <h2>Hébergement :</h2>
            <p>Ce site est hébergé par LWS (Ligne Web Services) - Société au capital de 500 000 Euros - RCS Paris B 851 993 683</p>
            <h2>Données personnelles :</h2>
            <p>SOS Diogene s'engage à respecter la confidentialité des données personnelles collectées sur ce 
                site. Pour plus d'informations sur notre politique de confidentialité, veuillez consulter notre 
                <span className="pc" onClick={() => {choixPage(10)}}> politique de confidentialité</span>.</p>
            <h2>Modification des mentions légales :</h2>
            <p>SOS Diogene se réserve le droit de modifier à tout moment les présentes mentions légales. Il est donc recommandé de consulter régulièrement cette page.</p>            
            <p>Date de dernière mise à jour : 24/04/2024</p>
        </div>
    );
}

export default Mention;