import "../styles/CreerDevis.css"
import {useState} from 'react'
import axios from "axios"
import chemin from "./Chemins"

function verifierDevis(devis)
{
    if (devis.nom === "" || !devis.nom.trim())
        return ("Le nom n'est pas renseigné")
    if (devis.prenom === "" || !devis.prenom.trim())
        return ("Le prénom n'est pas renseigné")
    if (devis.mail ==="" || !/\S+@\S+\.\S+/.test(devis.mail))
        return ("Veuillez renseignée une adresse mail correcte")
    if (devis.adresse === "")
        return ("L'adresse n'est pas renseignée")
    if (devis.nature_travail === "")
        return ("La nature du travail n'est pas renseignée")
    if (devis.regularite === "")
        return ("La régularité n'est pas renseignée")
    if (devis.hps === 0 && devis.regularite === "Régulier")
        return ("Le nombre d'heure par semaine n'est pas renseigné")
    if (devis.pps === 0 && devis.regularite === "Régulier")
        return ("Le nombre de passage par semaine n'est pas renseigné")
    if (devis.description === "")
        return ("La description du travail n'est pas renseignée")
    if (devis.images.type !== "image/png" && devis.images.type !== "image/jpeg" && devis.images !== "")
        return ("Mauvais format d'image, veuillez utiliser une photo au format JPEG ou PNG")
    if (devis.nuisibles === "")
        return ("La présence de nuisibles n'est pas renseignée")
    if (devis.apa === "")
        return ("La présence d'une APA n'est pas renseignée")
    if (devis.pch === "")
        return ("La présence d'un PCH n'est pas renseignée")
    if (devis.tutelle === "")
        return ("La présence d'un/une tuteur/tutrice n'est pas renseignée")
    else
        return ("OK")
}

function CreerDevis({idUtilisateur, changerIdUtilisateur, page, choixPage})
{
    const [devisState, changerDevisState] = useState({});
    const [regularite, changerRegularite] = useState(false);

    let devis = {
        id_demandeur: idUtilisateur,
        nom: "",
        prenom: "",
        nom_prenom: "",
        mail: "",
        adresse: "",
        nature_travail: "",
        regularite: "",
        hps: 0,
        pps: 0,
        images: "",
        description: "",
        nuisibles: "",
        apa: "",
        pch: "",
        tutelle: ""
    };
    let message_erreur = "";

    if (Object.keys(devisState).length !== 0)
    {
        devis = devisState;
        devis.images = "";
    }
    return (
        <div className="creer-devis">
            Nom : <input id="devis1" type="text" onChange={(event) => {devis.nom = event.target.value}}></input>{" "}
            Prénom : <input id="devis2" type="text" onChange={(event) => {devis.prenom = event.target.value}}></input>{" "}
            Adresse mail : <input id="devis3" type="text" onChange={(event) => {devis.mail = event.target.value}}></input><br></br><br></br>
            Adresse : <input id="devis4" type="text" className="adresse"
            placeholder="Exemple : 13 rue du Général Leclerc, 78100, Saint Germain en Laye"
            onChange={(event) => {devis.adresse = event.target.value}}></input><br></br><br></br>
            Nature du travail : <select id="devis5" onChange={(event) => {devis.nature_travail = event.target.value}}>
                <option value="">--Veuillez choisir une option--</option>
                <option value="Ménage">Ménage</option>
                <option value="Bricolage">Bricolage</option>
                <option value="Débarras">Débarras</option>
                <option value="Aide à la personne">Aide à la personne</option>
            </select>
            <span>  </span>
            Régularité du travail : <select id="devis6" onChange={(event) => {
                devis.regularite = event.target.value;
                changerDevisState(devis);
                if (devis.regularite === "Régulier")
                    changerRegularite(true);
                else
                    changerRegularite(false);
                }}>
                <option value="">--Veuillez choisir une option--</option>
                <option value="Occasionnel">Occasionnel</option>
                <option value="Régulier">Régulier</option>
            </select><br></br><br></br>
            {regularite &&
            (<div>
                Nombre d'heures par semaine : <input id="devis7" type="number" min="1" max="35" onChange={(event) => {devis.hps = event.target.value}}></input>
            <span>  </span>
            Nombre de passages par semaine : <input id="devis8" type="number" min="1" max="7" onChange={(event) => {devis.pps = event.target.value}}></input><br></br><br></br>
            </div>)}
            Description du travail :<br></br><textarea  id="devis13" rows={15} cols={100} 
            onChange={(event) => {devis.description = event.target.value}}></textarea><br></br>
            Photo à ajouter à la description : <input id="devis14" name="image-devis" type="file" accept="image/png, image/jpeg"
            onChange={(event) => {devis.images = event.target.files[0]}}></input>
            <br></br><br></br>
            Présence de nuisibles : <select id="devis9" onChange={(event) => {devis.nuisibles = event.target.value}}>
                <option value="">--Veuillez choisir une option--</option>
                <option value="Oui">Oui</option>
                <option value="Non">Non</option>
            </select><br></br><br></br><br></br>
            Bénéficiez vous de l'APA : <select id="devis10" onChange={(event) => {devis.apa = event.target.value}}>
                <option value="">--Veuillez choisir une option--</option>
                <option value="Oui">Oui</option>
                <option value="Non">Non</option>
            </select>
            <span>  </span>
            Bénéficiez vous d'une PCH : <select id="devis11" onChange={(event) => {devis.pch = event.target.value}}>
                <option value="">--Veuillez choisir une option--</option>
                <option value="Oui">Oui</option>
                <option value="Non">Non</option>
            </select><br></br><br></br>
            Êtes-vous sous tutelle : <select id="devis12" onChange={(event) => {devis.tutelle = event.target.value}}>
                <option value="">--Veuillez choisir une option--</option>
                <option value="Oui">Oui</option>
                <option value="Non">Non</option>
            </select>
            <br></br>
            <br></br>
            <button className="envoyer-devis" onClick={() => {
                message_erreur = verifierDevis(devis);
                if (message_erreur !== "OK")
                    alert(message_erreur);
                else if (message_erreur === "OK")
                    {
                        if (devis.images !== "")
                        {
                            const nom_image = Date.now() + "-" + devis.nom + "-" + devis.prenom + "1.png";
                            const formData = new FormData();
                            formData.append('image-devis', devis.images, nom_image);
                            devis.images = nom_image;
                            axios.post(chemin + "/devis/telecharger_image", formData);
                        }
                        devis.nom_prenom = devis.nom + " " + devis.prenom;
                        axios.post(chemin + "/devis/creer_devis", devis).then(() => {
                            alert("OK");
                        });
                        alert("Votre devis a bien été envoyé ! Vous allez être redirigé vers votre espace personnel.");
                        choixPage(8);
                    }
            }}>Envoyer le devis</button>
        </div>
    );
}

export default CreerDevis;