import "../styles/Corps.css"
import Accueil from "./Accueil";
import NousContacter from "./NousContacter";
import Intervention from "./Intervention";
import AideADomicile from "./Aide";
import Menage from "./Menage";
import MultiService from "./MultiService";
import EspacePersonnel from "./EspacePersonnel";
import Fonctionnement from "./Fonctionnement";
import Chantier from "./Chantier";
import Mention from "./Mention";
import PolitiqueConfidentialite from "./PolitiqueConfidentialite";
import VoirMesDevis from "./VoirMesDevis"
import CreerDevis from "./CreerDevis";
/*
    index des pages :
        1 = Fonctionnement
        2 = Intervention
        3 = Chantiers
        4 = Aide à domicile
        5 = Ménage
        6 = Multi-services
        7 = Nous contacter
        8 = Espace personnel
        9 = Mentions légales
        10 = Politique de confidentialité
        11 = Voir mes devis
        12 = Créer un devis
        0 = Accueil
*/

function Corps({page, choixPage, connecte, authentifier, idUtilisateur, changerIdUtilisateur})
{
    switch (page)
    {
        case (0):
            return (
                <Accueil page={page} choixPage={choixPage}/>
            );
        case (1):
            return (
                <Fonctionnement />
            );
        case (2):
            return (
                <Intervention />
            );
        case (3):
            return (
                <Chantier />
            );
        case (4):
            return (
                <AideADomicile />
            );
        case (5):
            return (
                <Menage />
            );
        case (6):
            return (
                <MultiService />
            );
        case (7):
            return (
                <NousContacter />
            );
        case (8):
            return (
                <EspacePersonnel connecte={connecte} authentifier={authentifier} page={page} choixPage={choixPage}/>
            );
        case (9):
            return (
                <Mention page={page} choixPage={choixPage}/>
            )
        case(10):
            return (
                <PolitiqueConfidentialite />
            )
        case(11):
            return (
                <VoirMesDevis idUtilisateur={idUtilisateur}/>
            )
        case(12):
            return (
                <CreerDevis idUtilisateur={idUtilisateur} changerIdUtilisateur={changerIdUtilisateur} 
                page={page} choixPage={choixPage}/>
            )
        default:
            return (<div>Test</div>)
    }
}

export default Corps;