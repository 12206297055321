import "../styles/Connexion.css";
import axios from "axios";
import chemin from "./Chemins"

function Connexion({connecte, authentifier, idUtilisateur, changerIdUtilisateur})
{
    let utilisateur = {mail: "", mdp: ""};

    return (
        <div className="connexion">
            <h1>Ce site est en cours de développement, veuillez vous authentifier pour y accéder</h1>
            Nom d'utilisateur : 
            <input id="connexion-mail" type="text" className="nom-utilisateur" onChange={(event) => {utilisateur.mail = event.target.value;}}></input>
            <br></br>
            Mot de passe :
            <input id="connexion-mdp" type="password" className="mot-de-passe" onChange={(event) => {utilisateur.mdp = event.target.value;}}></input>
            <br></br>
            <button type="submit" onClick={() => {
                axios.post(chemin + "/utilisateurs/connexion", utilisateur).then((res) => {
                    if (res.data.error === undefined)
                    {
                        authentifier(true);
                        changerIdUtilisateur(res.data.id);
                    }
                    else
                        alert(res.data.error);
                });
            }}>Envoyer</button>
        </div>
    );
}

export default Connexion;