import "../styles/Banniere.css"
import logo from "../images/logo_sos.png"
import { useState } from "react";

function Banniere({page, choixPage})
{
    const [test, activeTest] = useState(0);

    return (
        <div className="banniere">
            <ul className="menu-deroulant">
                <li className="curseur-pointeur" onClick={() => {choixPage(8)}}>
                    <b>Espace personnel</b>
                </li>
                <li className=" curseur-pointeur" 
                    onMouseEnter={() => {activeTest(1)}} 
                    onMouseLeave={() => {activeTest(0)}}>
                    <b>Qui sommes nous ?</b>
                    {test === 1 && 
                        <ul className="liste-deroulante">
                            <li className=" curseur-pointeur" onClick={() => {choixPage(1)}}>Notre fonctionnement</li>
                            <li className=" curseur-pointeur" onClick={() => {choixPage(2)}}>Notre zone d'intervention</li>
                            {/*<li className=" curseur-pointeur" onClick={() => {choixPage(3)}}>Nos chantiers</li>*/}
                        </ul>}
                </li>
                <li className=" curseur-pointeur" 
                onMouseEnter={() => {activeTest(2)}} 
                onMouseLeave={() => {activeTest(0)}}>
                    <b>Quels sont nos services ?</b>
                    {test === 2 && 
                        <ul className="liste-deroulante">
                            <li className=" curseur-pointeur" onClick={() => {choixPage(4)}}>Aide à domicile</li>
                            <li className=" curseur-pointeur" onClick={() => {choixPage(5)}}>Ménages</li>
                            <li className=" curseur-pointeur" onClick={() => {choixPage(6)}}>Multi-services</li>
                        </ul>}
                </li>
                <li className=" curseur-pointeur" onClick={() => {choixPage(7)}}>
                    <b>Nous joindre</b>
                </li>
            </ul>
            <img src={logo} alt="logo" className="img-logo curseur-pointeur" onClick={() => {choixPage(0)}}></img>
        </div>
    );
}

export default Banniere;