import "../styles/EspacePersonnel.css"
import axios from "axios";
import chemin from "./Chemins"

function EspacePersonnel({connecte, authentifier, page, choixPage})
{
    return (
        <div className="espace-personnel corps">
            <button className="bouton-espace-perso" onClick={() => {choixPage(11)}}>Voir mes demandes de devis</button><br></br>
            <button className="bouton-espace-perso" onClick={() => {choixPage(12)}}>Faire une nouvelle demande de devis</button><br></br>
            <button onClick={() => {
                axios.post(chemin + "/utilisateurs/deconnexion").then((res) => {
                    authentifier(false);
                    choixPage(0);
                });
            }} className="bouton-espace-perso">Se déconnecter</button>
        </div>
    );
}

export default EspacePersonnel;