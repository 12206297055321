import "../styles/AfficherDetailsDevis.css"

function AfficherDetailsDevis({mesDevis, details, afficherDetails})
{
    const urlImage = "/images/" + mesDevis.devis[details].images;
    let image = false;
    if (mesDevis.devis[details].images !== "")
        image = true;
    let regulier = false;
    if (mesDevis.devis[details].regularite === "Régulier")
        regulier = true;
    return (
        <div className="details-devis">
            <button onClick={() => {afficherDetails(-1)}}>Retour à la liste des devis</button>
            <div className="desc-devis">
                <div>
                    Identité du demandeur : {mesDevis.devis[details].nom_prenom}
                </div>
                <div>
                    Adresse mail de contact : {mesDevis.devis[details].mail}
                </div>
                <div>
                    Adresse du demandeur : {mesDevis.devis[details].adresse}
                </div>
                <br></br>
                <div>
                    Nature du travail demandé : {mesDevis.devis[details].nature_travail}
                </div>
                <br></br>
                <div>
                    Régularité du travail : {mesDevis.devis[details].regularite}
                </div>
                {regulier && (
                    <div>
                        Nombres d'heures par semaine : {mesDevis.devis[details].hps}
                        <br></br>
                        Nombres de passages par semaine : {mesDevis.devis[details].pps}
                    </div>
                )}
                <br></br>
                <div>
                    Description :<br></br>{'"' + mesDevis.devis[details].description + '"'}
                </div>
                {image && (<div>
                    <img src={urlImage} alt="Photo associée à la demande"></img>
                </div>)}
                <br></br>
                <div>
                    Présence de nuisibles : {mesDevis.devis[details].nuisibles}
                </div>
                <br></br>
                <div>
                    APA : {mesDevis.devis[details].apa} | PCH : {mesDevis.devis[details].pch} | Tutelle : {mesDevis.devis[details].tutelle}
                </div>
            </div>
        </div>
    );
}

export default AfficherDetailsDevis;