import "../styles/VoirMesDevis.css"
import { useEffect, useState } from "react";
import axios from "axios";
import AfficherDetailsDevis from "./AfficherDetailsDevis";
import chemin from "./Chemins"

function VoirMesDevis({idUtilisateur})
{
    const [details, afficherDetails] = useState(-1);
    const [mesDevis, changerMesDevis] = useState([]);

    useEffect(() => {axios.post(chemin + "/devis/voir_devis", {id_demandeur: idUtilisateur}).then((res) => {
        changerMesDevis(res.data);
    })}, [idUtilisateur]);
    if (!mesDevis.devis)
    {
        return (
            <div className="mes-devis">
                Chargement des devis en cours.
            </div>
            );
    }
    else
    {
        if (details === -1)
            return (
                <div className="mes-devis">
                    <ul>
                    {mesDevis.devis.map((devis, i) => (
                        <li key={i}>Devis de {devis.nom_prenom} pour {devis.nature_travail} créé le {devis.createdAt.split('T0')[0] + " "}
                        <span className="details" onClick={() => {afficherDetails(i)}}>Afficher les détails</span></li>
                    ))}
                    </ul>
                </div>
                );
        else
            return (
                <AfficherDetailsDevis mesDevis={mesDevis} details={details} afficherDetails={afficherDetails}/>
        );
    }
}

export default VoirMesDevis;